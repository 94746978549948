<!-- Sales or rent properties card, with or without swiper -->
<template>
  <div class="property-wrapper">
    <div class="property-box">
      <div class="swiper-wrapper">
        <router-link :to="'/property/' + id">
          <img :src="pictures" alt="property image" />
        </router-link>
      </div>
      <div class="property-details">
        <span class="property-details__location-span">{{ region }}</span>
        <div class="property-details__go-to">
          <router-link :to="'/property/' + id">
            <h3>{{ region }}, {{ address }}, {{ estateType }}</h3>
          </router-link>
        </div>

        <h6>
          <span>Цена: {{ price }} EUR </span>
          <span class="offer-type-label">{{ pricePerSqm }} EUR кв.м</span>
        </h6>
        <p class="font-class">{{ slicedDescription }}...</p>
        <ul>
          <li>
            <i class="material-icons">zoom_out_map</i> Квадратура:
            {{ areaSize }} кв.м.
          </li>
          <li><i class="material-icons">bed</i> Спални: {{ bedrooms }}</li>
          <li><i class="material-icons">bathtub</i> Бани: {{ bathrooms }}</li>

          <li><i class="material-icons">balcony</i> Тераси: {{ balconies }}</li>
        </ul>
        <div class="property-details__property-btn">
          <router-link :to="'/property/' + id"> Детайли </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css";

export default {

  props: [
    "pictures",
    "id",
    "region",
    "heading",
    "address",
    "description",
    "priceInEur",
    "pricePerSqm",
    "areaSize",
    "bedrooms",
    "bathrooms",
    "balconies",
    "offerType",
    "furnishedType",
    "estateType",
  ],

  computed: {
    slicedDescription() {
      if(this.description!=null && this.description.length>199 ){
        return this.description.slice(0, 200);
      }else{
        return this.description;
      }
      
    },
    price() {
      const formattedPrice = this.priceInEur.toLocaleString("EU-eu", {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      });
      return formattedPrice;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
</style>
