<template>
  <div style="min-height: 800px">
    <div>
      <top-info-bar :data="propertiesData"></top-info-bar>
      <toggle-advanced-seach :data="propertiesData"></toggle-advanced-seach>
      <div class="layout">
        <div class="filter-wrapper">
          <advanced-search></advanced-search>
        </div>
        <base-loading v-if="isLoading"></base-loading>
        <div class="properties-wrapper">
          <div v-for="property in propertiesData" :key="property.id">
            <!-- Render items for the current page -->
            <detailed-property-card
              :id="property.id"
              :region="property.regionName"
              :address="property.subRegionName"
              :description="property.description"
              :priceInEur="property.price"
              :bedrooms="property.bedroomCount"
              :bathrooms="property.bathCount"
              :balconies="property.balconyCount"
              :areaSize="property.surfaceAll"
              :pictures="property.topImageURL"
              :offerType="property.offerTypeName"
              :furnishedType="property.furnishedName"
              :estateType="property.estateTypeName"
              :pricePerSqm="property.pricePerM"
            ></detailed-property-card>
          </div>
        </div>
      </div>
      <!-- pagination component -->
      <div>
        <button
          :class="{
            'btn-pagination': currentPage !== 1,
            'btn-pagi-disabled': currentPage === 1,
          }"
          @click="previousPage"
          :disabled="currentPage === 1"
        >
          ◀
        </button>
        <span>Страница {{ currentPage }} от {{ totalPages }}</span>
        <button
          :class="{
            'btn-pagination': currentPage !== totalPages,
            'btn-pagi-disabled': currentPage === totalPages,
          }"
          @click="nextPage"
          :disabled="currentPage === totalPages"
        >
          ▶
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { usePropertiesStore } from "../stores/PropertiesStore";
import DetailedPropertyCard from "../components/property-cards/DetailedPropertyCard.vue";
import AdvancedSearch from "../components/filters/AdvancedSearch.vue";
import ToggleAdvancedSeach from "@/components/filters/ToggleAdvancedSeach.vue";
import TopInfoBar from "@/components/ui/TopInfoBar.vue";


export default {
  components: {
    DetailedPropertyCard,
    AdvancedSearch,
    ToggleAdvancedSeach,
    TopInfoBar,
  },

  methods: {
    previousPage() {
      const store = usePropertiesStore();
      if (store.currentPage > 1) {
        store.currentPage--;
      }
      store.fetchPropertiesData();
    },

    nextPage() {
      const store = usePropertiesStore();
      if (store.currentPage < store.allProperties.totalPages) {
        store.currentPage++;
      }
      store.fetchPropertiesData();
    },
  },

  computed: {
    propertiesData() {
      const propertiesStore = usePropertiesStore();
      return propertiesStore.propertiesData;
    },

    isLoading() {
      const propertiesStore = usePropertiesStore();
      return propertiesStore.isLoading;
    },

    paginatedProperties() {
      const propertiesStore = usePropertiesStore();
      return propertiesStore.paginatedProperties;
    },

    currentPage() {
      const propertiesStore = usePropertiesStore();
      return propertiesStore.currentPage;
    },
    totalPages() {
      const propertiesStore = usePropertiesStore();
      return propertiesStore.allProperties.totalPages;
    },
  },

  mounted() {
    const propertiesStore = usePropertiesStore();
    // propertiesStore.clearStoreFilter();
    propertiesStore.filter.forRent = 0;
    propertiesStore.filter.sell = 1;
    propertiesStore.fetchPropertiesData();
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/main.scss";
</style>
