<template>
  <div class="property-heading-card-wrapper">
    <div class="property-headings">
      <h2></h2>
      <div class="address-and-timestamp">
        <h2 class="address">{{ propertyType }} в {{ region }}</h2>
        <h3>{{ subRegion }}</h3>
      </div>
      <div class="property-price-info">
        <span class="property-price-info__whole-price"
          ><i class="material-icons">sell </i> Цена:
          {{ buildingPropertyPrice }} EUR</span
        >
        <span
          class="property-price-info__per-sqm"
          v-if="price === 'Провери...'"
        >
          {{ calculatedRangePerSqm }} EUR/кв.м</span
        >
        <span
          class="property-price-info__per-sqm"
          v-if="price !== 'Провери...'"
        >
          {{ pricePerSqm }} EUR/кв.м</span
        >
      </div>
      <div class="short-details">
        <ul>
          <li>
            <i class="material-icons">meeting_room</i>

            Вид имот: {{ propertyType }}
          </li>
          <li>
            <i class="material-icons">home</i> Вид строителство:
            {{ buildingType }}
          </li>
          <li>
            <i class="material-icons">fullscreen_exit</i> Квадратура:
            {{ surface }} кв. м.
          </li>
          <li><i class="material-icons">bed</i> Спални: {{ bedrooms }}</li>
          <li><i class="material-icons">bathtub</i> Бани: {{ bathrooms }}</li>
          <li><i class="material-icons">wc</i> Тоалетни: {{ toilets }}</li>
          <li><i class="material-icons">balcony</i> Тераси: {{ balconies }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "region",
    "subRegion",
    "price",
    "pricePerSqm",
    "surface",
    "bedrooms",
    "bathrooms",
    "toilets",
    "balconies",
    "propertyType",
    "buildingType",
    "heatingType",
    "builded",
  ],
  data() {
    return {
      estatePreviewData: [],
      property: null,
    };
  },

  computed: {
    propertyPrice() {
      const formattedPrice = this.price.toLocaleString("EU-eu", {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      });
      return formattedPrice;
    },
    buildingPropertyPrice() {
      if (this.price === "Провери...") {
        return `от ${this.minPriceInEur} до ${this.maxPriceInEur}`;
      } else {
        return this.propertyPrice;
      }
    },
    calculatedRangePerSqm() {
      const min = (this.minPriceInEur / this.minAreaSize).toFixed(2);
      const max = (this.maxPriceInEur / this.maxAreaSize).toFixed(2);
      const result = `${min} / ${max}`;
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
</style>
