<template>
  <top-info-bar :data="propertiesData"></top-info-bar>
  <toggle-advanced-seach :data="propertiesData"></toggle-advanced-seach>
  <div class="layout">
    <div class="filter-wrapper">
      <advanced-search></advanced-search>
    </div>
    <base-loading v-if="isLoading"></base-loading>
    <div class="properties-wrapper">
      <div v-for="property in propertiesData" :key="property.id">
        <buildings-detailed-card
          :id="property.id"
          :region="property.region"
          :heading="property.heading"
          :address="property.address"
          :description="property.description"
          :minPriceInEur="property.minPriceInEur"
          :maxPriceInEur="property.maxPriceInEur"
          :minAreaSize="property.minAreaSize"
          :maxAreaSize="property.maxAreaSize"
          :pictures="property.pictures"
          :offerType="property.offerType"
          :subRegion="property.subRegion"
        ></buildings-detailed-card>
      </div>
    </div>
  </div>
</template>

<script>
import { usePropertiesStore } from '../stores/PropertiesStore';
import AdvancedSearch from "../components/filters/AdvancedSearch.vue";
import ToggleAdvancedSeach from "@/components/filters/ToggleAdvancedSeach.vue";
import TopInfoBar from "@/components/ui/TopInfoBar.vue";
import BuildingsDetailedCard from "@/components/property-cards/BuildingsDetailedCard.vue";

export default {
  components: {
    AdvancedSearch,
    ToggleAdvancedSeach,
    TopInfoBar,
    BuildingsDetailedCard,
  },
  computed: {
    propertiesData() {
      const propertiesStore = usePropertiesStore();
      return propertiesStore.propertiesData.filter((property) => property.offerType === "Ново строителство");
    },
    isLoading() {
      const propertiesStore = usePropertiesStore();
      return  propertiesStore.isLoading;
    }
  },

  mounted() {
    const propertiesStore = usePropertiesStore();
    propertiesStore.fetchPropertiesData();
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/main.scss";
</style>
