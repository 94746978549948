import { createApp } from 'vue'
import { createPinia } from 'pinia';
// pinia persisted state
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import MobileHeader from "./components/header/MobileHeader.vue"
import DesktopHeader from "./components/header/DesktopHeader.vue"
import TheFooter from "./components/footer/TheFooter.vue"
import TheLogo from "./components/ui/TheLogo.vue"
import TheBreadcrumbs from "./components/ui/TheBreadcrumbs.vue"
import BaseLoading from "./components/ui/BaseLoading"
import router from './router'

import App from './App.vue'

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.component('mobile-header', MobileHeader);
app.component('desktop-header', DesktopHeader);
app.component('the-footer', TheFooter);
app.component('the-logo', TheLogo);
app.component('the-breadcrumbs', TheBreadcrumbs)
app.component('base-loading', BaseLoading)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)  // pini apersisted state library
app.use(pinia)
app.use(router);
app.use(ElementPlus);
app.mount('#app')
